button{
    outline: none;
    border: none;
    cursor: pointer;
}
.profilePage{
    width: 100%;
    display: flex;
}
.profilePageLeft{
    width: 220px;
    min-width: 220px;
    min-height: 100vh;
    position: relative;
}
.profilePageRight{
    width: calc(100% - 220px);
    background: #fff;
    padding: 30px 60px;
    border-radius: 30px;
}
.sidebarWrap{
    min-height: 100vh;    
}
.prSidebar{
    padding-bottom: 35px;
    box-sizing: border-box;
    min-height: calc(100vh - 156px);
}
.profileLogo{
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
}
.profileLogo img{
    display: block;
    height: 48px;
    padding-left: 25px;
}
.prsUserLeft {
    width: 50px;
}
.prsUserLeft>img {
    display: block;
    width: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.prsUserRight {
    padding-left: 15px;
    padding-right: 5px;
    width: calc(100% - 50px);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}
.prsUserRightName {
    font-size: 14px;
    color: #192a3e;
}
.prsUserRightEmail, .prsUserRightName {
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    width: 100%;
}
.prsUserRightEmail {
    font-size: 11px;
    color: #90a0b7;
    margin-top: 2px;
    word-break: break-all;
    padding-right: 35px;
}
.prsBr.m15 {
    margin: 15px 0;
}
.prsLg2 {
    margin-top: 6vh;
}
.prsLg2>img {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 150px;
}
.prsUserMenu {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}
.prsUserMenu.bottom{
    padding-top: 35px;    
}
.prsUserMenuLi {
    cursor: pointer;
}
.prsUserMenuLiA {
    width: 100%;
}
.prsUserMenuLiA.current {
    color: #79ff7b!important;
}
.prsUserMenuLiAImg {
    width: 16px;
    display: block;
    margin-right: 14px;
}
.prsUserMenuLiA.current .prsUserMenuLiAImg path{
    fill: #3cd33d;
}
.profilePageRightContent{
    background: #f5f6f8;
    width: 100%;
    height: 100%;
}
.dashboardnav {
    height: 66px;
    background: #fff;
    border-bottom: 1px solid #ebeff2;
    justify-content: space-between;
    padding: 0 36px;
    box-sizing: border-box;
    align-items: center;
}
.dashboardnav, .profilePageContent {
    width: 100%;
    display: flex;
}
.logoutBtn {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    font-family: 'Roboto', sans-serif;
}
.logoutBtn>img {
    width: 15px;
}
.profilePageDashboardContent {
    padding-top: 25px;
}
.profilePageDashboardContentTitle {
    font-family: "Poppins",sans-serif;
    color: #334d6e;
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 25px;
    text-align: center;
}
.settingsForm, .settingsFormInput {
    display: flex;
    flex-wrap: wrap;
}
.settingsForm, .settingsFormInput {
    display: flex;
    flex-wrap: wrap;
}
.settingsFormInput {
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: 15px 0 40px;
    text-align: center;
}
.settingsFormInput>label {
    font-family: "Poppins",sans-serif;
    width: 100%;
}
.settingsFormInput>input[type=text] {
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    font-family: "Poppins",sans-serif;
    border: none;
    border-bottom: 1px solid #333;
    text-align: center;
    width: 90%;
    max-width: 300px;
    margin-top: 10px;
    padding-bottom: 5px;
}
.settingsFormBtn{
    width: 100%;
    padding: 25px 0 50px 0;
}
.settingsFormBtn > button{
    display: block;
    margin: auto;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    width: 100%;
    max-width: 120px;
    height: 35px;
    background: #0d6efd;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins",sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}
.reportsList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.reportsListItem{
    width: 100%;
    max-width: 600px;
    margin: 15px 0;
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.downloadImg{
    width: 25px;
    cursor: pointer;
}
.reportsListItemLeft{
    display: flex;
    align-items: center;
}
.reportsListItemLeftDate{
    font-size: 12px;
    font-family: "Poppins",sans-serif;
    margin-left: 20px;
}
.reportsListItemLeftName{
    font-family: "Poppins",sans-serif;
}
.dashboardContent{
    width: 100%;
    display: flex;
    padding: 30px 15px;
    box-sizing: border-box;
}
.dashboardContentLeft,
.dashboardContentRight{
    margin: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
}
.dashboardContentLeft{
    width: 60%;
    padding: 15px 22px 35px 22px;
    box-sizing: border-box;
}
.dashboardContentRight{
    width: 40%;
}
.dashboardContentLeftTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.dashboardContentLeftTitleLeft{
    color: #334D6E;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    font-size: 13px;
}
.dashboardContentLeftTitleLeft span{
    color: #0d6efd;
}
.dashboardContentLeftTitleRight{
    font-size: 13px;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    color: #334D6E;
}
.dashboardContentLeftLine{
    margin-top: 15px;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #0d6efd;
}
.dashboardContentLeftToday{
    color: #192A3E;
    font-size: 15px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
}
.dashboardContentLeftToday span{
    color: #90A0B7;
}
.dashboardContentLeftWeek{
    margin-top: 30px;
}
.dashboardContentLeftWeekUl{
    padding: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    display: flex;
}
.dashboardContentLeftWeekUlLi{
    width: 14.2857142857%;
    text-align: center;
    cursor: pointer;
}
.dashboardContentLeftWeekUlLiDay{
    font-size: 13px;
    line-height: 19px;
    color: rgba(51, 77, 110, .5);
    font-family: "Poppins",sans-serif;
}
.dashboardContentLeftWeekUlLiDate{
    margin-top: 7px;
}
.dashboardContentLeftWeekUlLiDate span{
    font-family: "Poppins",sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #4C5862;
}
.dashboardContentLeftWeekUlLi .active .dashboardContentLeftWeekUlLiDate span{
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #0d6efd;
    margin: auto;
}
.dashboardContentLeftWeekBr{
    margin-top: 24px;
    width: 100%;
    height: 2px;
    display: block;
    position: relative;
    margin-bottom: 5px;
}
.dashboardContentLeftWeekBr:before{
    position: absolute;
    content: '';
    width: calc(100% + 44px);
    height: 2px;
    background-color: #EBEFF2;
    left: -22px;
    top: 0;
}
.dashboardContentLeftWeekTasks{
    margin-top: 25px;
}
.dashboardContentLeftWeekTasksItem{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 15px 17px 15px 25px;
    margin-top: 24px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    box-sizing: border-box;
    max-width: 600px;
}
.dashboardContentLeftWeekTasksItem:firstchild{
    margin-top:0;
}
.dashboardContentLeftWeekTasksItemTop{
    display: flex;
    justify-content: space-between;
}
.taskTitle{
    font-size: 14px;
    color: #4C5862;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
}
.taskType{
    font-weight: 500;
    font-size: 12px;
    color: #90A0B7;
    font-family: "Poppins",sans-serif;
}
.dashboardContentLeftWeekTasksItemMiddle{
    margin-top: 12px;
}
.taskDate{
    font-size: 13px;
    font-family: "Poppins",sans-serif;
    color: rgba(76, 88, 98, .5);
}
.taskDate span{
    color: #4C5862;
    font-family: "Poppins",sans-serif;
    margin-left: 10px;
}
.taskInfo{
    font-family: "Poppins",sans-serif;
    font-size: 13px;
    color: #333;
    margin: 10px 0 20px 0
}
.dashboardContentLeftWeekTasksItemBottom{
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.dashboardContentLeftWeekTasksItemBottomLeft{
    display: flex;
    align-content: center;
    align-items: center;
}
.taskName{
    margin-left: 11px;
    font-size: 13px;
    color: #707683;
    font-family: "Poppins",sans-serif;
}
.taskStatus{
    width: 84px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
    font-size: 11px;
    font-family: "Poppins",sans-serif;
    color: #FFFFFF;
}
.taskStatus.active{
    background: #FFB946;
}
.taskStatus.completed{
    background: #0d6efd;
}
.taskStatus.ended{
    background: #F7685B;
}
.adminTasksList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 100px;
}
.adminTasksListItem{
    width: 40%;
    text-decoration: none;
}
.adminTasksBtn{
    margin-top: 20px;
}
.adminTasksBtnNewTask{
    margin: auto;
    width: 120px;
    height: 45px;
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #0d6efd;
}
.linkFix{
    text-decoration: none;
}
.allTasksItem{
    width: 100%;
}
.atFilterTitle{
    text-align: center;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
}
.atFilterItem{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.atFilterItemName{
    margin-right: 10px;
    font-size: 13px;
    font-family: "Poppins",sans-serif;
}
.atFilterItemINP{
    appearance: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #333;
    margin: 0 5px;
}
.atFilterItemBTN{
    font-family: "Poppins",sans-serif;
    font-size: 12px;
    width: 60px;
    height: 25px;
    text-decoration: none;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #0d6efd;
    cursor: pointer;
}
.userItem{
    color: #333;
}
.alltasksgraph{
    width: 100%;
}
.alltasksgraph > div{
    margin: auto;
    max-width: 550px;
}
.adminItem{
    width: 40%;
    text-decoration: none;
}
.reports{
    padding: 30px 15px 40px 15px;
}
.reprotsTitle{
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    font-family: "Poppins",sans-serif;
    color: #0d6efd;
    font-weight: 500;
    text-transform: uppercase;
}
.reportsItem{
    width: 100%;
    max-width: 650px;
    margin: 15px auto;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 20px 15px;
    display: flex;
    text-decoration: none;
    margin-bottom: 40px;
    box-sizing: border-box;
}
.reportsItemLeft{
    width: 85%;
}
.reportsItemRight{
    width: 15%;
    display: flex;
    align-items: center;
}
.reportsItemLeftLi{
    font-size: 14px;
    font-family: "Poppins",sans-serif;
    color: rgba(76, 88, 98, .8);
    list-style: none;
    margin: 10px 0;
}
.reportsItemLeftLi > span{
    color: #333;
}
.reportStatus.open{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins",sans-serif;
    background: #F7685B;
    font-weight: 300;
    font-size: 14px;
    font-family: "Poppins",sans-serif;
    color: #FFFFFF;
    border-radius: 3px;
}
.reportStatus.closed{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins",sans-serif;
    background: #0d6efd;
    font-weight: 300;
    font-size: 14px;
    font-family: "Poppins",sans-serif;
    color: #FFFFFF;
    border-radius: 3px;
}
.adminfilter{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0 15px 0;
}
.adminfiltertitle{
    font-family: "Poppins",sans-serif;
    font-size: 15px;
    margin-right: 10px;
}
.filterInp{
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    font-family: "Poppins",sans-serif;
    border: none;
    border-bottom: 1px solid #333;
    text-align: center;
    width: 100%;
    max-width: 200px;
    padding-bottom: 5px;
}
.filterBtn{
    text-decoration: none;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #0d6efd;
    border: none;
    padding: 5px 15px;
    margin-left: 10px;
    cursor: pointer;
}
.rightNav{
    padding: 5px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(229, 231, 235);
    justify-content: space-between;
}
.rightBody{
    padding: 25px;
}
.newBTN{
    padding: 12px 26px 14px 17px;    
    border-radius: 5px;
    background-color: #0d6efd;
    text-decoration: none;
    color: #272727;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    align-items: center;
}
.newBTN svg{
    margin-right: 19px;
}
.newBid{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
}
.newBidBody{
    padding: 40px 50px;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    height: 96vh;
    max-height: 780px;
    overflow-y: scroll;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.noscroll{
    margin: auto;
    overflow-y: inherit!important;
    height: auto;
    max-height: none!important;
}
.hide{
    display: none!important;
}
.newTech{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
}
.newTechBody{
    padding: 40px 50px;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    max-height: 75vh;
    overflow-y: scroll;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.newTechTitle{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 22px;
}

.int1{
    margin-top: 22px;
}
.int1Name{
    font-size: 16px;
    font-family: "Poppins",sans-serif;
    margin-bottom: 9px;
}
.int1Val{
    width: 100%;
    height: 39px;
    padding: 0 17px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #707070;
    border-radius: 5px;
    font-family: "Poppins",sans-serif;
}
.int1Text{
    padding: 12px 17px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
    font-family: "Poppins",sans-serif;
}
.int2{
    display: flex;
    margin-top: 22px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.int2Name{
    width: 40%;
}
.int2Val{
    width: 55%;
    height: 39px;
    padding: 0 17px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #707070;
    border-radius: 5px;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    text-align: center;
}
.ibtn1{
    margin-top: 17px;
}
.ibtn1 > button{
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
    font-family: "Poppins",sans-serif;
    color: #272727;
    background: #0d6efd;
}
.ibtn2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}
.ibtn2 > button{
    width: calc(50% - 5px);
    height: 47px;
    font-family: "Poppins",sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
    color: #272727;
}
.ibtn2 > button:last-child{
    background: #0d6efd;
}

.rightNavRight{
    display: flex;
    align-items: center;
}
.navBTN{
    appearance: none;
    background: none;
    display: flex;
    align-items: center;
    margin-left: 40px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #272727;
    box-sizing: border-box;
    padding: 0;
}
.navBTN svg{
    margin-right: 11px;
}
.navBTN .img{
    width: 20px;
    text-align: center;
    margin-right: 11px;
}
.navBTN .img svg{
    margin: auto;
}
.navBTNDropdown{
    position: relative;
}
.navBTNDropOut{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 15px;
    list-style: none;
    top: 100%;
    margin: 0;
    box-sizing: border-box;
}
.navBTNDropOut li{
    margin-top: 15px;
    width: 100%;
}
.navBTNDropOut li a{
    display: flex;
    width: 100%;
}
.navBTNDropOut li:first-child{
    margin-top: 0;
}
.navBTNDropOut li .navBTN{
    margin-left: 0;
}
.newBidBodyLeft,
.newBidBodyRight{
    width: 50%;
}
.newBidBodyLeft{
    padding-right: 35px;
}
.newBidBodyRight{
    padding-left: 35px;
}
.newBidBody{
    display: flex;
    align-items: flex-start;
}
.newBidTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newBidTitleLeft{
    font-size: 21px;
    color: #000000;
}
.newBidTitleRight{
    font-size: 11px;
    color: #000000;
}
.newBidTitleRight span{
    font-size: 13px;
    margin-left: 7px;
}
.nbtrow{
    margin-top: 22px;
}
.nbt1{
    display: flex;
    align-items: center;
}
.nbt1Title{
    padding-right: 11px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    white-space: nowrap;
    color: rgba(0,0,0,0.6);
}
.nbt1Content{
    display: flex;
    width: 100%;
}
.nbt1Content button{
    width: 50%;
    background: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid rgba(112, 112, 112, .4);
    height: 38px;
    font-family: 'Roboto', sans-serif;
    color: rgba(0,0,0,0.6);
    box-sizing: border-box;
}
.nbt1Content input{
    width: 100%;
}
.nbt1Content button.selected{
    color: rgba(0,0,0,1);
    background-color: #0d6efd;
    border-color:  #0d6efd;
}
.nbt1Content button:first-child{
    border-radius: 5px 0 0 5px;
    border-right: 0;
}
.nbt1Content button:last-child{
    border-radius: 0 5px 5px 0;
    border-left: 0;
}
.nbt2Title{
    margin-bottom: 9px;
    font-size: 16px;
    color: #000;
}
.nbtSelect{
    appearance: none;
    background: none;
    border: none;
    height: 38px;
    border: 1px solid rgba(112, 112, 112, .4);
    font-size: 16px;
    width: 100%;
    padding: 0 17px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    cursor: pointer;
}
.nbtcheckbox{
    display: flex;
    cursor: pointer;
}
.nbtcheckbox input{
    margin-right: 8px;
}
.nbtInput{
    appearance: none;
    background: none;
    border: none;
    height: 38px;
    border: 1px solid rgba(112, 112, 112, .4);
    font-size: 16px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    padding: 0 17px;
    border-radius: 5px;
    box-sizing: border-box;
}
.newBidBodyRight div:first-child{
    margin-top: 0;
}
.nbtrowbtn{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.nbtrow:first-child{
    margin-top: 0;
}
.nbtrowbtn button{
    width: calc(50% - 5px);
    height: 47px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    background-color: #E2E2E2;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    color: #272727;
    font-size: 16px;
}
.nbtrowbtn button.yellow{
    background-color: #0d6efd;
}
.clientsWrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.clientsWrap .client{
    width: 48%;
}

.clientItem{
    width: 100%;
    padding: 24px;
    background-color: #4E4E4E;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 15px;
    align-items: center;
}
.clientItem .row{
    width: 22.5%;
    color: #fff;
    font-size: 16px;
    word-wrap: break-word;
    padding-right: 15px;
}
.clientItem .minirow{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.clientItem .minirow button{
    appearance: none;
    cursor: pointer;
    background-color: transparent;
}
.markBtn{
    appearance: none;
    cursor: pointer;
    background: transparent;
    position: relative;
}
.markUl{
    position: absolute;
    top: -100%;
    right: 100%;
    z-index: 9;
    list-style: none;
    background: #fff;
    padding: 0;
    display: none;
}
.markBtn:hover .markUl{
    display: block;
}
.markUl li{
    width: 100%;
}
.markUl li button,
.markUl li a{
    text-decoration: none;
    background-color: #fafafa;
    margin: 0;
    display: inline-block;
    color: #000;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
}
.profwrap{
    width: 100%;
    margin: 50px auto auto auto;
    max-width: 800px;
    display: flex;
}
.profwrapFluid{
    width: 100%;
    margin: 50px auto auto auto;
    max-width: 1200px;
}
.profLeft{
    width: 25%;
}
.profLeft img{
    display: block;
    width: 100%;
}
.profRight{
    width: 75%;
    padding: 10px 30px;
}
.profRightRowVal{
    margin-top: 4px;
}
.profRightRowName{
    font-weight: 600;
    font-size: 16px;
}
.profRightRow{
    margin-bottom: 20px;
}
.profRightBtns{
    display: flex;
    margin-top: 70px;
    justify-content: space-around;
}
.profRightBtns1{
    width: calc(30% - 5px);
    height: 47px;
    background: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #E2E2E2;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    color: #272727;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px 0!important;
}
.profRightBtns2{
    width: calc(30% - 5px);
    height: 47px;
    background: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #E2E2E2;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-size: 16px;
    background-color: #0d6efd;
    border-radius: 8px 0!important;

    background-color: #04A550!important;
    color: #fff!important;
    font-weight: 600!important;
    border: none!important;
    border-radius: 18px 4px!important;
    padding: 8px 26px!important;
}
.pmwrap{
    width: 100%;
    max-width: 1200px;
    margin: auto auto;
}
.pwtitle{
    background-color: rgb(78, 78, 78);
    display: flex;
}
.pwtitle .img{
    width: 10%;
    display: flex;
    justify-content: center;
    height: 60px;
    color: #fff;
    align-items: center;
}
.pwtitle .col{
    width: calc(80% / 3);
    color: #fff;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pwitem{
    display: flex;
}
.pwitem .img{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pwitem .img img{
    display: block;
    width: 100%;
}
.pwitem .col{
    width: calc(80% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.profRightRow{
    max-width: 250px;
}
.profRightRow input{
    padding: 3px 6px;
    margin-top: 5px;
    font-size: 18px;
}
.profLeft input{
    width: 100%;
    margin-top: 10px;
}
.workTypes{
    width: 100%;
    margin-bottom: 30px;
    max-width: 1200px;
    margin: auto auto 50px auto;
}
.workTypesTop{
    display: flex;
    justify-content: space-between;
    align-items:center;
    background: rgb(78, 78, 78);
    color: #fff
}
.newBTN.fix{
    padding: 7px 20px;
}
.workTypesTopLeft{
    padding: 0 7px;
}
.workTypesBottomItem{
    display: flex;
}
.workTypesBottomItemLeft,
.workTypesBottomItemMiddle,
.workTypesBottomItemRight{
    width: 33.33%;
    padding: 5px 10px;
    display:flex;
    justify-content: center;
    align-items:center;
    border: 1px solid #ccc
}
.editBtn{
    padding: 4px 8px;
    margin: 0 10px;
    background-color: #0d6efd;
    border-radius: 5px;
}
.editBtn.dark{
    background-color: rgb(34, 34, 34);
    color: #fff;
}
.statfilter{
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.statfilterItem{
    flex: 1;
    max-width: 300px;
    margin: 0 10px;
}
.pmwrap.stat{
    max-width: 600px;
    margin-top: 50px;
}
.pmwrap.stat .col{
    flex: 1;
    padding: 5px;
}
.statchart{
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
}
.bid{
    /* background: #B9B9B9; */
}
.bid.future{
    background: #ce4545;
}
.bid.active{
    background: #61a800;
}
.bid.missed{
    background: #0d6efd;
}
.bid.ended{
    background: #B9B9B9;
}
.pwtitle.fix .col{
    height: 30px;
}
.avatarDropdown{
    outline: none;
    border: none!important;
}
.avatarDropdown:after{
    display: none!important;
}
.avatarDropdown:focus{
    outline: none;
    box-shadow: none!important;
}
.darkColor{
    color: #272727!important;
}
.btn{
    border-radius: 18px 4px!important;
}
.sidebar-nav{
    padding: 50px 0!important;
}
.sidebar-nav .nav-title{
    padding: 0;
}
.sidebar-nav .nav-link{
    padding: 6px 12px!important;
    color: #111827!important;
    font-weight: 600!important;
    opacity: .8;
}
.sidebar-nav .nav-link{
    border:none!important;
    padding: 20px 20px 20px 25px!important;
}
.nav-tabs{
    border:none!important;
}
.nav-link{
    color: #111827!important;
}
.nav-tabs .nav-link{
    border:none!important;
    padding: 10px 26px!important;
    margin-right: 5px!important;
}
.nav-tabs .nav-link:hover{
    border:none!important;
}
.nav-tabs .nav-link.active{
    background-color: #04A550!important;
    color: #fff!important;
    font-weight: 600!important;
    border:none!important;
    border-radius: 18px 4px!important;
    padding: 8px 26px!important;
}
.sidebar-nav .nav-link.current{
    background: #fff;
    border-radius: 30px 0 0 30px;
    position: relative;
    opacity: 1;
}
.sidebar-nav .nav-link.current:before{
    width: 31px;
    height: 31px;
    display: block;
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M0.5 30C24.1 30 30 10 30 0H31.5V32H0.5V30Z' fill='white'/%3E%3C/svg%3E");
    bottom: 98%;
    right: 0;
    z-index: -1;
}
.sidebar-nav .nav-link.current:after{
    width: 31px;
    height: 31px;
    display: block;
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M0.5 2C24.1 2 30 22 30 32H31.5V0H0.5V2Z' fill='white'/%3E%3C/svg%3E");
    top: 98%;
    right: 0;
    z-index: -1;
}
.sidebar-nav .nav-link:hover{
    border-radius: 30px 0 0 30px;
    background: #e7e7e7!important;
}
.avatar{
    border-radius: 10px 4px!important;
}
.wishlistTopBar{
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemCartWishlsit{
    padding: 10px;
}
.itemCartWishlsit .card{
    height: 100%;
    border-radius: 32px 8px!important;
    background: #F3F6FF;
    padding: 8px 26px;
}

span.start-100{
    left: 80%!important;
}
.btn-primary{
    background-color: #04A550!important;
    border-color: #04A550!important;
    padding: 8px 26px!important;
}
.rightNav .btn-primary{
    padding: 10px!important;
}
.SVG_ICON{
    display: block;
    height: 20px;
    color: #04A550;
}
.Card_remove_btn{
    background: transparent;
    float: right;
}
.card-body{
    position: relative;
}
.Card_tag{
    position: absolute;
    right: 16px;
    top: 16px;
    background: #fff;
    padding: 2px 8px;
    font-size: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
}
.Card_remove_btn{
    position: relative;
}
.Card_remove_btn:hover:before{
    content: attr(tooltip);
    position: absolute;
    bottom: 50%;
    left: 50%;
    background: #fff;
    border: #333;
    padding: 3px 10px;
    font-size: 12px;
    z-index: 9;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.Card_open_btn{
    display: inline-flex;
    align-items: center;
    gap: 6px;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
}
.Card_open_btn:hover:before{
    content: attr(tooltip);
    position: absolute;
    bottom: 50%;
    left: 50%;
    background: #fff;
    border: #333;
    padding: 3px 10px;
    font-size: 12px;
    z-index: 9;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.Card_open_btn svg{
    height: 20px;
}
.cardImage{
    width: auto!important;
    max-height: 150px;
    margin: auto;
    display: block;
}
.profileAvatar > div{
    width: 4rem;
    height: 4rem;
}
.profileDivider{
    margin-bottom: 20px;
    border-radius: 32px 8px!important;
    background: #F3F6FF;
    padding: 8px 26px;
}
.profileDividerTitle{
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1.2;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 15px;
}
.profileDividerBody{
    padding: 1rem 1rem;
}