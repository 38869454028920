@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #F7FAFF!important;
  padding: 25px 0;
}
.container {
  max-width: 1900px;
  box-sizing: border-box;
  padding: 0 15px;
  margin: auto;
}
.dcontainer-fluid{
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  max-width: 1324px;
}