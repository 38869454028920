@media screen and (min-width: 11001px){
    .dashboardnavLeftBurger{
        display: none;
    }
}
@media screen and (max-width:1100px){
    .dashboardnav{
        padding: 0;
    }
    .dashboardnavLeftBurger{
        width: 30px;
        height: 30px;
        position: relative;
    }
    .dashboardnavLeftBurger> span{
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #333;
        top: 0;
        left: 0;
    }
    .dashboardnavLeftBurger> span:before,
    .dashboardnavLeftBurger> span:after{
        position: absolute;
        content: '';
        width: 30px;
        height: 3px;
        background-color: #333;
    }
    .dashboardnavLeftBurger> span:after{
        top: 10px;
        left: 0;
    }
    .dashboardnavLeftBurger> span:before{
        top: 20px;
        left: 0;
    }
    .reportsItem,
    .userItem{
        flex-wrap: wrap;
    }
    .reportsItemLeft,
    .userItemLeft{
        width: 100%;
    }
    .reportsItemRight,
    .userItemRight{
        width: 100%;
        margin-top: 15px;
    }
    .prsLg2>img{
        display: none;
    }
    .dashboardContent{
        flex-wrap: wrap;
    }
    .dashboardContentLeft{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
    }
    .dashboardContentRight{
        width: 100%;
        margin-top: 20px;
    }
    .dashboardContentLeftWeekUl{
        flex-wrap: wrap;
    }
    .dashboardContentLeftWeekUlLi{
        width: 50%;
        margin-bottom: 10px;
        border-bottom: 1px solid #000;
        padding-bottom: 5px;
    }
    .profilePageDashboardContent{
        padding: 0 15px;
    }
    .dashboardContentLeftTitleRight{
        display: none;
    }
    .profilePageDashboardContentTitle{
        padding-top: 10px;
    }
    .settingsFormInput{
        width: 100%;
    }
    .loginContent{
        flex-wrap: wrap;
    }
    .loginContentLeft{
        display: none;
    }
    .loginContentRight{
        width: 100%;
        padding: 0;
    }
    .alertMessage{
        padding: 5px;
        max-width: 200px;
        z-index: 99;
    }
    .profilePage{
        flex-wrap: wrap;
    }
    .profilePageLeft{
        width: 100%;
        min-height: auto;
    }
    .sidebarWrap{
        min-height: auto;
    }
    .rightNavRight{
        display: none;
    }
    .rightNav{
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
    }
    .profilePageRight{
        width: 100%;
    }
    .profileLogo{
        width: 100px;
        padding: 10px;
    }
    .prSidebar{
        display: none;
    }
    .container{
        padding: 0;
    }
}

@media(min-width: 780px){
    .hidepk{
        display: none!important;
    }
}
@media(max-width: 779px){
    .hidemob{
        display: none!important;
    }
    .bidsWrapMobRow{
        background-color: rgb(185, 185, 185);
        margin-bottom: 30px;
        border-radius: 5px;
        border: 1px solid #333;
    }
    .bidsWrapMobRowTop{
        display: flex;
    }
    .bidsWrapMobRowTopLeft,
    .bidsWrapMobRowTopMiddle,
    .bidsWrapMobRowTopRight{
        width: 33.33%;
        padding: 10px 0;
        text-align: center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bidsWrapMobRowTopLeft{
        font-size: 15px;
    }
    .bidsWrapMobRowTopMiddleItem{
        width: 100%;
        display: flex;
    }
    .bidsWrapMobRowTopMiddleItemCol{
        width: 50%;
        font-size: 14px;
    }
    .bidsWrapMobRowTopMiddleItemCol.w{
        font-weight: 700;
    }
    .bidsWrapMobRowTopMiddlei{
        background-color: #fff;
        width: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
    }
    .bidsWrapMobRowBottom{
        display: flex; 
    }
    .bidsWrapMobRowBottomRight,
    .bidsWrapMobRowBottomLeft{
        width: 50%;
        font-size: 12px;
        text-align: center;
        padding: 9px 0;
    }
    .bidsWrapMobItem{
        display: flex;
        padding: 5px 5px 10px 5px;
        justify-content: center;
        border-bottom: 1px solid #000;
        flex-wrap: wrap;
    }
    .bidsWrapMobItem .title{
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
    .bidsWrapMobItem .value{
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        text-align: center;
        margin-top: 6px;
    }
    .bidsWrapMobItem .btn{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .newBidBody{
        padding: 30px 15px;
        flex-wrap: wrap;
        box-sizing: border-box;
    }
    .newBidBodyLeft,
    .newBidBodyRight{
        padding: 0;
        width: 100%;
    }
}