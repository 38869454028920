.profwrapFluid{
    max-width: 1100px;
    background: #fff;
    padding: 30px 60px;
    border-radius: 30px;
    margin-top: 0;
}
.rightBody{
    padding: 0;
}
.pubWishlistTitle{
    text-align: center;
}

@media(max-width: 999px){
    .profwrapFluid{
        max-width: calc(100% - 44px);
        padding: 30px 22px;
    }
}