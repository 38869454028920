.loginMain {
    width: 100%;
    min-height: 100vh;
    /* background-image: url(/static/media/loginBg.ed5b0bbd.jpg); */
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
}
.loginContent {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 15px 30px;
    box-sizing: border-box;
}
.loginContent.admin{
    justify-content: center;
}
.loginContentLeft {
    width: 65%;
}
.simpleImg {
    width: 100%;
    display: block;
}
.loginContentRight {
    width: 35%;
    padding: 0 10px;
}
.loginLogo{
    width: 100%;
    display: block;
    text-align: center;
}
.loginSubTitle {
    margin: 15px 0 0 0;
    color: #2d3748;
    font-size: 16px;
}
.LoginFormInput, .LoginFormInput>label {
    width: 100%;
    display: block;
    margin-bottom: 14px;
}
.loginTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 5px 0 30px;
    color: #01893F;
}
.loginSubTitle, .loginTitle {
    font-family: "Roboto",sans-serif;
}
.LoginFormInput, .LoginFormInput>label {
    width: 100%;
    display: block;
    margin-bottom: 14px;
}
.LoginFormInput>label {
    font-size: 16px;
    font-family: "Roboto",sans-serif;
    color: #4a5568;
}
.LoginFormInput>input {
    background: #fff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    font-size: 14px;
    color: #2d3748;
    font-family: "Roboto",sans-serif;
}
.loginFormRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}
.loginFormResetPass, .loginFormSubmit {
    cursor: pointer;
    font-family: "Roboto",sans-serif;
}
.loginFormResetPass {
    color: #01893F;
    font-size: 14px;
}
.loginFormSubmit {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    width: 100%;
    height: 65px;
    background: #01893F;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
}
.loginFormRow.pd20 {
    padding: 22px 0;
}
.loginFormRegLink, .loginFormRegP {
    font-weight: 400;
    font-size: 16px;
    font-family: "Roboto",sans-serif;
    margin-top: 15px;
}
.loginFormRegLink {
    color: #01893F;
    cursor: pointer;
    text-decoration: none;
}
.loginContent.register {
    justify-content: center;
}
.registerContent {
    width: 100%;
    max-width: 600px;
    padding: 0 25px;
    box-sizing: border-box;
}
.alertMessage {
    position: fixed;
    width: 100%;
    max-width: 250px;
    padding: 15px 10px;
    right: 15px;
    top: 15px;
    background-color: #01893F;
    color: #fff;
    text-align: center;
}